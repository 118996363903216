import { createApp } from "vue";

import "../public/css/style.css";

// import Wallet from "@/components/Wallet";

import App from "./App.vue";
export let app = createApp(App);

// import { createPinia } from 'pinia';
// // pinia needs to be created before router
// const pinia = createPinia();
// import { useSettingsStore } from "@/stores/SettingsStore";
// app.use(pinia);

import { createRouter, createWebHistory } from "vue-router";

// // SmartBch
// const routes = [
//   {
//     path: "/",
//     redirect: "/ExpShop",
//   },

//   {
//     path: "/ExpShop",
//     name: "EXP Shop",
//     component: () => import("./components/ExpShop.vue"),
//   },

//   {
//     path: "/pixelguy",
//     name: "Pixelguy",
//     component: () => import("./components/Pixelguy.vue"),
//   },

//   {
//     path: "/shorai",
//     name: "Shorai",
//     component: () => import("./components/Shorai.vue"),
//   },

//   {
//     path: "/kensho",
//     name: "Kensho",
//     component: () => import("./components/Kensho.vue"),
//   },

//   {
//     path: "/kenshoX",
//     name: "Kensho Exclusive",
//     component: () => import("./components/KenshoX.vue"),
//   },

//   {
//     path: "/nftLookup",
//     name: "NFT Lookup",
//     component: () => import("./components/NftLookup.vue"),
//   },

//   // { path: '/wallet', component: Wallet },

//   {
//     path: "/admin",
//     name: "Admin Internal",
//     component: () => import("./components/Admin.vue"),
//   },

//   {
//     path: "/:catchall(.*)*",
//     name: "404",
//     component: () => import("./views/404.vue"),
//   },

//   // {
//   //     path: '/nftFaucet', name: 'NFT Faucet', component: () => import('./components/NftFaucet.vue')
//   // }, // !!!testnet only!!!
// ];

// Doge
const routes = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "NftMint" */ "./components/NftMint.vue"),
  },

  {
    path: "/kimonOld",
    name: "Kimon Old Contract",
    component: () =>
      import(/* webpackChunkName: "KimonOld" */ "./components/KimonOld.vue"),
  },
  {
    path: "/kimon",
    name: "Kimon New Contract",
    component: () =>
      import(/* webpackChunkName: "Kimon" */ "./components/Kimon.vue"),
  },

  // { path: '/wallet', component: Wallet },
  // { path: '/nftLookup', component: NftLookup },

  {
    path: "/NftMint",
    name: "Mint",
    component: () =>
      import(/* webpackChunkName: "NftMint" */ "./components/NftMint.vue"),
  },
  {
    path: "/ExpShop",
    name: "EXP Shop",
    component: () =>
      import(/* webpackChunkName: "Exp" */ "./components/ExpShop.vue"),
  },

  {
    path: "/admin",
    name: "Admin Internal",
    component: () => import("./components/Admin.vue"),
  },
  // { path: '/nftFaucet', component: NftFaucet }, // testnet
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach(async (to, from) => {
//     const settingsStore = useSettingsStore();
//     console.log(to, from, settingsStore)
// });

app.use(router);

router.isReady().then(() => {
  app.mount("#app");
});

<template>
  <div class="popup" v-if="visible" @click="visible = false">
    <div class="popupX">x</div>
    <div class="row align-items-center">
      <h3 class="text-monospace animate-popup">
        {{ title }}
      </h3>
      <br />
      <p class="text-monospace animate-popup">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      text: "",
      lastTimeout: 0,
      minTimeout: 3000, // in ms
      visible: false,
    };
  },

  methods: {
    show(_title, _text, _timeOut) {
      // to be called from parent using refs:
      // <Popup ref="popup" />
      // showPopup (_title, _text, _timeOut) {
      //   this.$refs.popup.show(_title, _text, _timeOut);
      // },

      let that = this;
      that.title = _title.toUpperCase();
      that.text = _text.toUpperCase();

      if (that.lastTimeout > 0) {
        clearTimeout(that.lastTimeout);
      }

      if (_timeOut > 0) {
        if (_timeOut < that.minTimeout) {
          _timeOut = that.minTimeout;
        }

        that.lastTimeout = setTimeout(function () {
          that.visible = false;
        }, _timeOut);
      }

      that.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  background-color: rgb(16, 152, 73);
  opacity: 0.9;
  margin-top: 2;
  margin-bottom: 2;
  padding: 20px;
  position: fixed;
  z-index: 999;
  top: 110px;
  left: 100px;
  right: 20px;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(23, 246, 116, 0.94),
    0 0 40px rgba(9, 244, 107, 0.683);

  .popupX {
    color: rgba(23, 246, 116, 0.95);
    font-size: 20px;
    position: fixed;
    top: 155px;
    right: 30px;
  }
  .popupX:hover {
    color: rgb(0, 255, 106);
    transform: scale(1.5);
  }

  .animate-popup {
    text-transform: uppercase;
    background-image: linear-gradient(
      -25deg,
      #090909 0%,
      #5e694f 50%,
      #090909 90%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 10s linear infinite;
    display: inline-block;
    text-align: center;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
}
</style>